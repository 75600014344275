@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

/* @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); * For IE6-8 *
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
} */

h1, h2, h3 {
  font-family: 'Montserrat-Bold';
}

/* FlexBox Grid System: https://roylee0704.github.io/react-flexbox-grid/ */

/* 
Extra small devices (portrait phones, less than 544px) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/
/*1rem = 16px*/
div {font-size:1rem;}
h1 {font-size:1.5rem !important;}
h2 {font-size:1.25rem !important; margin-top: 1.8rem !important;}
h3 {font-size:1.1rem !important; margin-top: 1.6rem !important;}
h3.no-margin-top { margin-top: 0rem !important; }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  div {font-size:1.1rem;}
  h1 {font-size:1.7rem !important;}
  h2 {font-size:1.4rem !important; margin-top: 1.6rem !important;}
  h3 {font-size:1.2rem !important; margin-top: 1.5rem !important;}
  h3.no-margin-top { margin-top: 0rem !important; }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
/* @media (min-width: 768px) {  
  div {font-size:1.1rem;}
  h1 {font-size:1.8rem !important;}
  h2 {font-size:1.3rem !important;}
  h3 {font-size:1.2rem !important;}
} */
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  div {font-size:1.2rem;}
  h1 {font-size:2.0rem !important;}
  h2 {font-size:1.6rem !important; margin-top: 2.2rem !important;}
  h3 {font-size:1.3rem !important; margin-top: 1.8rem !important;}
  h3.no-margin-top { margin-top: 0rem !important; }
}
 
/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {  
  div {font-size:1.4rem;}
  h1 {font-size:2.2rem !important;}
  h2 {font-size:1.8rem !important;}
  h3 {font-size:1.5rem !important;}
}
@media (min-width: 1440px) {  
  div {font-size:1.6rem;}
  h1 {font-size:2.3rem !important;}
  h2 {font-size:2.0rem !important;}
  h3 {font-size:1.7rem !important;}
} */

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Montserrat';
  background-color: #adeaff; /*#56d9ff;*/
  min-height: 100vh;
}

.Header {
  display: flex;
  flex-direction: column;
  /* height: 40vmin; */
  background-color: rgba(255, 255, 255, 0.5);
  text-align: left;
  
  /* old */
  margin: 3%;
  margin-bottom: 0;
  border-radius: 10px;
  border: 2px solid #8abbcc;
  padding: 25px;
  
  /* new */
  /* border: 1px solid #8abbcc;
  margin: 0;
  margin-bottom: 25px;
  padding: 30px; */

  /* optional */
  /* margin-top: 25px; */
}
@media (min-width: 1200px) {
  .Header {
    /* margin-left: auto;
    margin-right: auto;
    width: 70%; */
    margin-left: 12%;
    margin-right: 12%;
  }  
}

.TileContainer {
  /* display: flex;
  flex-direction: column; */
  /* justify-content:space-between; */
  /* height: 40vmin; */
  /* background-color: rgba(255, 255, 255, 0.5);
  text-align: left; */
  
  /* old */
  margin: 3%;
  /* margin-bottom: 0; */
  /* border-radius: 10px;
  border: 2px solid #8abbcc; */
  /* padding: 25px; */

  
}
@media (min-width: 768px) {  
  .TileContainer {
    /* margin: 6%; */
  }
}
@media (min-width: 1200px) {
  .TileContainer {
    /* margin-left: auto;
    margin-right: auto;
    width: 70%; */
    margin-left: 12%;
    margin-right: 12%;
  }  
}

.Tile {
  /* display: flex;
  flex-direction: column; */
    /* height: 40vmin; */
  background-color: rgba(255, 255, 255, 0.5);
  text-align: left;

  width: 100%;

  /* old */
  /* margin: 3%;
  margin-bottom: 0; */
  /* width: 95%; */
  border-radius: 10px;
  border: 2px solid #8abbcc;
  /* padding: 25px; */
  
  /* new */
  /* border: 1px solid #8abbcc;
  margin: 0;
  margin-bottom: 25px;
  padding: 30px; */

  /* optional */
  /* margin-top: 25px; */
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .Tile {
    margin-right: 10%;
  }
  .TileRight {
    margin-right: 0%;
  }
}
@media (min-width: 1200px) {
  .Tile {
  }  
}

.download-button-container {
  /* flex-direction: row; */
  /* border: 1px solid black; */
  /* justify-content: 'center';
  align-items: 'center'; */
}

.download-button-item {
  /* border: 1px solid red; */
  text-align: center;
}

.download-button-ios {
  display: flex;
  justify-content: flex-end;
}
.download-button-android {
}

/* Side by side */
@media (min-width: 768px) { 
  .download-button-ios {
    text-align: right;
  }
  .download-button-android {
    text-align: left;
  }
}

.header-text {
  /* font-size: 15pt; */
}

img.download-button {
  /* height: 12vmin; */
  margin: -5px;
  width: 65%;
  /* pointer-events: none; */
  /* border: 1px solid red; */
  /* margin: -20px; */
}

video.video {
  height: 60vmin
}

.mydiv {
  /* border: 1px solid black; */
  height: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* margin: -40px; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  /* background-color: #56d9ff; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12pt; /* calc(10px + 2vmin); */
  color: black;
}

.App-link {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.navbar-logo {
  background-color: white;
  border-radius: 100px;
  margin-right: 15px;
}

.navbar {
  font-family: 'Montserrat';
}

.AlarmContainer {
  /* display: flex; */
  /* flex-direction: row; */
  direction: horizontal;
  /* justify-content: space-between; */
  align-items: center;
  border: 2px solid #8abbcc;
  border-radius: 1vh;
  margin-bottom: 2vh;
  padding: 1vh;
  font-family: 'Montserrat-bold';
  /* width: 50% */
  background-color: #e6f9ff;
}

.AlarmContainer:hover {
  background-color: #8abbcc;
}

.AlarmColumn {
  /* vertical-align: middle; */
  /* border: 1px solid green; */
  padding-left: 2vh;
  padding-right: 2vh;
}

.no-margin {
  margin: 100
}

.img-screenshot {
  border: 1px solid #8abbcc;
  border-radius: 7px;
}